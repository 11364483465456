<template>
  <div>
    <div>
      <!--      <v-select-->
      <!--        v-model="currentCompany.id"-->
      <!--        :items="companiesNoFilter"-->
      <!--        item-text="name"-->
      <!--        item-value="id"-->
      <!--        label="Компанія"-->
      <!--        dense-->
      <!--        outlined-->
      <!--        disabled-->
      <!--      ></v-select>-->
      <div class="mb-5">
        <span class="mr-1">Компанія:</span>
        <span class="font-weight-medium">{{ currentCompany.name }}</span>
      </div>
    </div>
    <div v-for="(filter, index) in filteredFilterDefs" :key="index" class="custom-height">
      <CommonFilter
        :values="filter.values"
        :label="filter.label"
        :disabled="!isVisible"
        :options="filter.options.list"
        :item-text="filter.options.text"
        :item-value="filter.options.value"
        @select="filter.applyFunc($event)"
        show-toggle-button
      />
    </div>
  </div>
</template>

<script>
import CommonFilter from '@/components/common/filters/CommonFilter'
import { mapState } from 'vuex'

export default {
  name: 'PushNotificationFilters',

  components: { CommonFilter },

  props: {
    filterDefs: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('companies', ['currentCompany']),

    filteredFilterDefs() {
      return this.filterDefs.filter((item) => item.show)
    },
  },
}
</script>

<style scoped lang="scss">
.custom-height {
  height: 66px;
}
</style>
